// Step 1: Import React
import * as React from 'react'
import Layout from '../components/Layout'
import BlogList, {INodePost} from '../components/BlogList'
import { useStaticQuery, graphql } from 'gatsby'
// Step 2: Define your component
const IndexPage = () => {
  const data: INodePost = useStaticQuery(graphql`
        query {
          allMdx(sort: {order: DESC, fields: frontmatter___date}) {
            nodes {
              frontmatter {
                date(formatString: "dddd, DD/MM/yyyy", locale: "Es-Ni")
                title
                hero_image_alt
                hero_image {
                  childrenImageSharp {
                    gatsbyImageData
                  }
                }
                tags
              }
              id
              excerpt
              slug
            }
          }
        }
    `)
  return (
    <Layout pageTitle="Inicio">
      <BlogList title="Ultimas entradas" data={data}/>
      
    </Layout>
  )
}
// Step 3: Export your component
export default IndexPage